import React, { useState, useEffect } from "react";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { IoMdClose } from "react-icons/io";
import Slide from "@mui/material/Slide";
// import "../BookingDetails.css";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { notification } from "antd";
import { CheckCircleOutlined, ExclamationCircleOutlined, DeleteFilled } from "@ant-design/icons";
import moment from "moment";
// import { availableSlots, rescheduleBooking } from '../../controllers/bookings/boardingControllers'
import { Link, useNavigate } from "react-router-dom";
import LoadingEffect from '../../components/Loading/LoadingEffect';
import { deleteNotifications as deleteNotificationAPI } from "../../controllers/notifications/notificationsControllers";
import { Card, Col, Row } from 'antd';
import Meta from "antd/es/card/Meta";
import {BASE_URL} from "../../config/constants";

const BookingFilterDrawer = ({ drawerOpen, RequesttoggleDrawer, notificationsList }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(()=>{
    if(notificationsList.length > 0) {
      setIsLoading(false)
    }
  });
  
  const handleDeleteNotification = async (id) => {
    try {
      const options = {
        url: `notifications/delete-notifications`,
        postData: { id : id }
    }
      const res = await deleteNotificationAPI(options);
      if(res.status === true){
        notification.success({
          message: 'Success!',
          description: 'Deleted successfully.',
          icon: <ExclamationCircleOutlined style={{ color: 'green' }} />,
        });
        RequesttoggleDrawer();
      } else{
        notification.error({
          message: 'Error',
          description: 'There was a problem while deleting  the notification.',
          icon: <ExclamationCircleOutlined style={{ color: '#f5222d' }} />,
        });
      }
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'There was a problem while deleting the notification.',
        icon: <ExclamationCircleOutlined style={{ color: '#f5222d' }} />,
      });
    }
  };


  // const navigate = useNavigate(); // Use the hook to get the navigate function
  const handleCardClick = (notificationtype) => {
    if (notificationtype) {
      if (notificationtype === "shop") {
        window.location.href = BASE_URL+'orders/list'; // Navigate to orders list
      } else {
        window.location.href = BASE_URL+`bookings/${notificationtype}/list`; 
      }
    }
  };

  

  return (
    <Drawer
      anchor="right"
      open={drawerOpen}
      onClose={RequesttoggleDrawer}
      className="reuest_filter_drawer_main_section"
    >
      {/* Select From Date & Time */}
      <Slide direction="left" in={drawerOpen}>
        <div className="myDrawer drawer_for_width_re_schedule">
          <div className="myMainDrawerClass">
            <Typography
              variant="h5"
              component="div"
              gutterBottom
              className="myFilterClass"
            >
              Notifications : 
            </Typography>

            <div>
              <IconButton onClick={RequesttoggleDrawer}>
                <IoMdClose />
              </IconButton>
            </div>
          </div>
          {notificationsList?.length > 0 ? (notificationsList.map((items, index)=>(<>
            <Card style={{ marginTop: 16 }}
              type="inner"
              title={`${items.title}`}
              key={index}
              extra={<Link to={false} onClick={()=>handleDeleteNotification(items._id)}><DeleteFilled style={{color:"red"}}/></Link>}
              onClick={() => handleCardClick(items?.type)}
            >
              {items.message} {items?.type}
              <Meta style={{ borderTop: "1px solid #f0f0f0", marginTop: "5px" }}
                description={moment(`${items.createdAt}`).fromNow()}
              />
            </Card>
          </>))) : ''}
        </div>
      </Slide>
      <LoadingEffect isLoading ={isLoading} />
    </Drawer>
  );
};

export default BookingFilterDrawer;

// BY Deafult Open
